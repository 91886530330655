<template>
  <div>
    <!-- modal trigger button -->

    <b-row>
      <b-col>
        <b-dropdown-item @click="showModal">
          <feather-icon icon="Edit2Icon" class="mr-50" />
          <span>change password</span>
        </b-dropdown-item>
      </b-col>
    </b-row>

    <b-modal ref="my-modal-password" modal-class="modal-info" hide-footer title="update user">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-observer ref="userInfoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">

              <small class="text-muted"> Enter the user Details. {{ user.name }} </small>
            </b-col>




            <b-col md="12">
              <b-form-group label="password" label-for="register-password">
                <validation-provider #default="{ errors }" name="password" vid="password" rules="required">
                  <b-form-input id="register-password" v-model="user.password" name="register-password"
                    :state="errors.length > 0 ? false : null" placeholder="" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>


          </b-row>
        </validation-observer>
      </form>
      <b-row variant="info">
        <b-col md="3">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="" variant="outline-secondary" block
            @click="hideModal">
            No
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="" variant="outline-info" block @click="toggleModal">
            <feather-icon icon="Edit2Icon" class="mr-50" />
            <span class="mr-50 text-info">updatePassword</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormCheckbox,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import useriesApi from "@/apiServices/userApi";
import { required, email, max_value, numeric } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import imagesApi from "@/apiServices/imagesApi";
import vSelect from "vue-select";
import countries from "@/auth/countries";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  props: ["user"],
  mixins: [togglePasswordVisibility],

  components: {
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      max_value,
      numeric,
      countries,
      options: ["video", "pdf", "powerpoint"],
      prams: {
        id: "",
      },
    };
  },
  created() {
    this.user.password = "";
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    updateuserPassword() {
      this.$refs.userInfoRules.validate().then((success) => {
        if (success) {
          useriesApi.changePassword(this.user).then((response) => {
            if (response.data) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "user password changed",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
          });
          this.$refs["my-modal-password"].toggle("#toggle-btn");
        }
      });
    },
    showModal() {
      console.log(this.id + "this id");

      this.$refs["my-modal-password"].show();
    },
    hideModal() {
      this.$refs["my-modal-password"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden

      this.updateuserPassword();
    },
  },
};
</script>
