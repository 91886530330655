<template>
  <b-card-code title="All users " no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->

        <!-- filter -->
        <b-form-group label="Filter" label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <sendMail :selectedStudents="selectedStudents" v-if="selectedStudents.length > 0" />
        <addNewLeson />
      </div>
    </b-card-body>
    <b-form-checkbox-group id="checkbox-group-2" v-model="selectedStudents" class="w-100">
      <b-table v-if="users.length > 0" striped hover responsive class="position-relative" :per-page="perPage"
        :items="users" :fields="fields">
        <template #cell(profileImg)="data">
          <!-- {{ data.title }} -->
          <b-form-checkbox :value="data.item">
            <b-avatar :src="data.item.profileImg" />
          </b-form-checkbox>
        </template>

        <template #cell(Action)="data">
          <!-- <p>
          Using v-html directive: <span v-html="data.item.description"></span>
        </p> -->
          <!-- {{ data.title }} -->
          <b-row>
            <span>
              <span>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                  </template>
                  <!-- <b-dropdown-item @clieck="">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item> -->
                  <editlesson :user="data.item" />
                  <changePassword :user="data.item" />
                  <!-- <b-dropdown-item>
                <feather-icon icon="TrashIcon" class="mr-50 text-danger" />
                <span class="mr-50 text-danger">Delete</span>
              </b-dropdown-item> -->
                  <deletelesson @deleteuserFromlist="deleteuserFromlist" :id="data.item._id" />
                </b-dropdown>
              </span>
            </span>
          </b-row>

          <!-- {{ data }} -->
          <!-- <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge> -->
        </template>
      </b-table>
    </b-form-checkbox-group>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <!-- <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group> -->

      <!-- pagination -->
      <div>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="prams.limit" first-number last-number
          prev-class="prev-item" next-class="next-item" class="mb-0">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <template #code>
      <!-- {{ codeKitchenSink }} -->
    </template>
  </b-card-code>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
// import { codeKitchenSink } from "./code";
import userApi from "@/apiServices/userApi";
import deletelesson from "@/views/users pages/delete.vue";
import editlesson from "@/views/users pages/edit.vue";
import addNewLeson from "@/views/users pages/add.vue";
import changePassword from "@/views/users pages/changePassword.vue";
import sendMail from "@/views/courses pages/components/sendmail.vue";

export default {
  components: {
    sendMail,
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    deletelesson,
    addNewLeson,
    editlesson,
    changePassword
  },
  data() {
    return {
      prams: {
        sort: "-createdAt",
        page: 1,
        limit: 100,
        title: "",
      },
      options: ["video", "pdf", "powerpoint"],
      selectedStudents: [],

      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      filter: "",
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "profileImg",
          label: "profile Img",
        },
        { key: "name", label: "name" },
        { key: "email", label: "email" },
        { key: "phone", label: "phone" },
        { key: "role", label: "role" },
        { key: "courses.length", label: "courses" },
        { key: "nationality", label: "nationality" },

        "Action",
      ],
      /* eslint-disable global-require */
      users: [],
      /* eslint-disable global-require */
      status: [],
      //   codeKitchenSink,
    };
  },
  created() {
    this.getusers();
  },
  computed: {},
  mounted() {
    // this.totalRows = this.items.length;
  },
  watch: {
    currentPage() {
      this.prams.page = this.currentPage;

      this.getusers();
    },
    filter() {
      this.prams.page = this.currentPage;
      this.prams.name = this.filter;

      this.getusers();
    },
  },
  methods: {
    deleteuserFromlist(id) {
      console.log("hi from parent " + id + "******" + this.users.length);
      this.users = this.users.filter(function (obj) {
        return obj._id != id;
      });
      console.log("hi ******" + this.users.length);
    },
    getusers() {
      if (this.filter == "") {
        delete this.prams.name;
      }
      userApi.getusers(this.prams).then((response) => {
        this.users = [];
        if (response.data) {
          this.users = response.data;
          this.currentPage = response.paginationResult?.currentPage;
          this.totalRows =
            response.paginationResult?.numberOfPages * this.prams.limit;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "get All users",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      //   this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
